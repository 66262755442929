import Link from "next/link";

import dynamic from "next/dynamic";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);
const Price = dynamic(() =>
  import("grandus-lib/components-atomic/price/Price")
);

const ProductCardSimple = ({
  name,
  urlTitle,
  finalPriceData,
  priceData,
  photo,
}) => {
  return (
    <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
      <a className="item simpleItem">
        <div className="image">
          <Image photo={photo} size={"200x240"} type={"jpg"} />
        </div>
        <h3>{name}</h3>
        <Price
          priceData={finalPriceData}
          options={{ hideVatPrice: true, mainPriceClass: "price" }}
        />
        {priceData?.price !== finalPriceData?.price ? (
          <Price
            priceData={priceData}
            options={{ hideVatPrice: true, mainPriceClass: "old-price" }}
          />
        ) : (
          ""
        )}
      </a>
    </Link>
  );
};

export default ProductCardSimple;
